
// export const EXCHNAGE_URL = 'https://judgify-api.phanomprofessionals.com/api/admin'
export const EXCHNAGE_URL = 'http://localhost:3600/api/admin'


export const USER_EXCHNAGE_URL = 'http://localhost:3600/api/user'


// export const IMAGES_URL = 'https://judgify-api.phanomprofessionals.com/uploads/'
export const IMAGES_URL = 'http://localhost:3600/uploads/'

